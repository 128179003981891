import 'reflect-metadata';
import _ from 'lodash';

/** ソースに記述した変数のメタデータを取得する為のキー */
const REFLECT_DESIGN_TYPE = 'design:type';

/** ソースに記述していない変数のメタデータを指定・取得する為のキー */
const REFLECT_CUSTOM_TYPE = 'custom:type';

/** ソースに記述した配列の要素のメタデータを取得する為のキー */
const REFLECT_CUSTOM_ELEMENT_TYPE = 'custom:element-type';


/**
 * メタデータから型名を取得します
 * metaType.nameはIEで取得できない
 *
 * @param metaType design:type または custom:type のメタデータ
 * @returns 型名
 */
function getTypeName(metaType: any): string { // eslint-disable-line @typescript-eslint/no-explicit-any
  const typeName = metaType.toString().match(/function\s*([^\s(]+)/);
  if (typeName === undefined) throw new Error('getTypeName error');
  return typeName[1];
}

/**
 * decorators fieldType
 * typeを指定した場合、メタデータを指定します
 * decoratorを指定しない変数は、design:type でも metadataが取得できないみたい
 *
 * @param type constructor ※配列の場合は必ず引数に型を設定する
 */
export function fieldType(type?: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
  return (target: any, key: string) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    if (type !== undefined) {
      const designType = Reflect.getMetadata(REFLECT_DESIGN_TYPE, target, key);
      const typeName = getTypeName(designType);
      const defineTarget = typeName === 'Array' ? REFLECT_CUSTOM_ELEMENT_TYPE : REFLECT_CUSTOM_TYPE;
      Reflect.defineMetadata(defineTarget, type, target, key);
    }
  };
}

/**
 * 厳密な型定義用基底クラス
 */
export default abstract class TypeStrictData {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any

  /**
   * 初期化処理
   *
   * @param keys key配列
   * @param values value配列
   */
  public init(keys: string[], values: string[]): void {
    for (let columnIndex = 0; columnIndex < keys.length; columnIndex++) {
      const prop = keys[columnIndex];
      const designType = Reflect.getMetadata(REFLECT_DESIGN_TYPE, this, prop);
      if (designType === undefined) {
        // key配列にはキーが定義されているが、実行クラスには定義されていない場合はスルー
        continue;
      }
      this[prop] = this.validateFieldType(values[columnIndex], prop, designType);
    }
  }

  /**
   * 型チェック（必要に応じて型変換）して、問題ある場合は例外を発生させます
   * 問題ない場合、独自クラスはそのインスタンスを、その他の型はそのままの値を返却します
   *
   * @param value 変数を値
   * @param prop 変数名
   * @param propType 変数のメタデータ
   * @returns
   */
  private validateFieldType(value: any, prop: string, propType: any): any { // eslint-disable-line @typescript-eslint/no-explicit-any
    // nullの場合はOKとする
    if (_.isNull(value)) return value;

    let typeName = getTypeName(propType);
    if (typeName !== 'Array') {
      // 型の指定があればそちらを使用
      const customType = Reflect.getMetadata(REFLECT_CUSTOM_TYPE, this, prop);
      if (customType !== undefined) typeName = getTypeName(customType);
    }

    switch (typeName) {
      case 'Number':
        if (_.isFinite(value))         return value;
        if (_.isFinite(Number(value))) return Number(value);
        throw new Error(prop + ' is not Number');
      case 'String':
        if (_.isString(value)) return value;
        throw new Error(prop + ' is not String');
      case 'Boolean':
        if (_.isBoolean(value))     return value;
        else if (value === 'true')  return true;
        else if (value === 'false') return false;
        throw new Error(prop + ' is not Boolean');
      case 'Array':
        const elementType = Reflect.getMetadata(REFLECT_CUSTOM_ELEMENT_TYPE, this, prop);
        if (elementType === undefined) throw new Error(prop + ' has no element type');
        return (value as any[]).map((el) => this.validateFieldType(el, prop, elementType)); // eslint-disable-line @typescript-eslint/no-explicit-any
      default:
        // クラス内のクラスを再帰で作成、constructor ～ validate が呼ばれる
        return new propType(value);

    }
  }

}
