import TypeStrictData from '@/classes/common/TypeStrictData';

/**
 * TSV文字列を指定クラスの配列に変換します
 *
 * @param type 返却対象クラス型種別
 * @param tsv TSV文字列
 * @return 指定クラスの配列
 */
export const parseTsv = <T extends TypeStrictData>(type: {new(): T}, tsv: string): T[] => {
  const list: T[] = [];
  const rows: string[] = tsv.split('\n');
  const keys: string[] = rows[0].split('\t');
  for (let rowIndex = 1; rowIndex < rows.length; rowIndex++) {
    if (rows[rowIndex].length === 0) continue;
    const values: string[] = rows[rowIndex].split('\t');
    // 指定クラスを生成・初期化し、listに追加
    const each: T = new type();
    each.init(keys, values);
    list.push(each);
  }
  return list;
};

/**
 * 問題一覧から指定セクション、ユニットの問題を取得します
 * ※じゃんけん、りょうり用
 *
 * @param questions
 * @param sectionNo
 * @param unitNo
 * @returns
 */
export const getQuestionData = <T extends {questionNo: number}>(questions: T[], sectionNo: number, unitNo: number): T => {
  const questionNo = (sectionNo - 1) * 10 + unitNo;
  const question   = questions.find(q => q.questionNo === questionNo);
  if (question === undefined) {
    alert('問題取得エラー');
    throw new Error(`no question master: ${questionNo}`);
  }

  return question;
};
